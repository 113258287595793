import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import styled from 'styled-components';

const StyledContainer = styled.div`
  ${tw`container w-full h-full sm:max-w-full md:max-w-full lg:max-w-full p-0 mx-auto p-2 box-border`}
  & {
  }
`;

const Layout = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
