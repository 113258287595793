import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { initial } from './../constants/initialData';
import {
  stateReducer,
  update,
  updateStatuses,
} from './../reducers/stateReducer';

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initial);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);

export const useStoreState = () => {
  const { state, dispatch } = useStore();
  return {
    state: state,
    update: payload => dispatch(update(payload)),
    updateStatuses: payload => dispatch(updateStatuses(payload)),
  };
};

StoreProvider.propTypes = {
  children: PropTypes.any,
};
