import pl from './../assets/lang/pl.png';
import en from './../assets/lang/en.png';
import ru from './../assets/lang/ru.png';
import de from './../assets/lang/de.png';

export const languages = [
  { code: 'pl', name: 'polish', icon: pl },
  { code: 'en', name: 'english', icon: en },
  { code: 'de', name: 'german', icon: de },
  { code: 'ru', name: 'russian', icon: ru },
];
