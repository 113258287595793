export const headOpMode = [
  {
    id: 'event',
    name: 'event',
  },
  {
    id: 'manual',
    name: 'manual',
  },
  {
    id: 'antifreeze',
    name: 'antifreeze',
  },
];

export const initial = {
  temperatureHome: 18,
  temperatureHomeHover: false,
  temperatureLivingRoom: 18,
  temperatureLivingRoomHover: false,
  temperatureLivingRoomChanged: false,
  headOpMode: 'events',
  comfortMode: false,
  comfortModeHover: false,
  lightningHome: false,
  lightningHomeHover: false,
  lightningBedroom: false,
  lightningBedroomHover: false,
  schedule: false,
  scheduleHover: false,
  lamp: false,
  lampHover: false,
  lampChanged: false,
  energy: 100,
  energyHover: false,
  energyMonth: 24.64,
  energyMonthHover: false,
  electricSockets: false,
  electricSocketsHover: false,
  electricSocketsChanged: false,
  washingMachine: false,
  washingMachineHover: false,
  washingMachineChanged: false,
  waterValves: false,
  waterValvesHover: false,
  waterValvesChanged: false,
  blinds: 0,
  blindsHover: false,
  blindsPosition: 50,
  blindsChanging: false,
  curtains: 0,
  curtainsHover: false,
  curtainsPosition: 50,
  curtainsChanging: false,
  statuses: [],
  cameraHover: false,
};
