import React, { useState, useEffect } from 'react';
import { StoreProvider } from './store/store';
import { IntlProvider } from 'react-intl';
import Layout from './components/Layout';
import Home from './components/pages/Home';

function App() {
  const [locale, setLocale] = useState('pl');
  const [localeMessages, setLocaleMessages] = useState(false);

  const changeLanguage = lang => {
    setLocale(lang);
  };

  const fetchLocale = async () => {
    const response = await fetch(`locale/${locale}/ui.json`).then(r =>
      r.json()
    );
    setLocaleMessages(response);
  };

  useEffect(() => {
    fetchLocale();
  }, [locale, fetchLocale]);

  return (
    locale && (
      <IntlProvider
        defaultLocale={'pl'}
        locale={locale}
        messages={localeMessages}
      >
        <StoreProvider>
          <Layout>
            <Home onChange={() => false} changeLanguage={changeLanguage} />
          </Layout>
        </StoreProvider>
      </IntlProvider>
    )
  );
}

export default App;
